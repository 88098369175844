import { composeApp } from 'shared/app/shell';

import accountPaymentMethodApp from 'account-payment-method/app/payment-method-sub-app';
import accrualEarnRatesBundle from 'shared/app/bundles/accrual-earn-rates';
import geolocationBundle from 'shared/app/bundles/geolocation';
import menuBundle from 'shared/app/bundles/menu';
import orderingApp from './ordering-sub-app';
import pickupApp from 'pickup/app/pickup-sub-app';
import scriptsBundle from 'shared/app/bundles/scripts';
import storeLocatorApp from 'store-locator/app/store-locator-subapp';
import svcCardsBundle from 'shared/app/bundles/svc-cards';
import userBundle from 'shared/app/bundles/user';
import viewportBundle from 'shared/app/bundles/viewport';
import walletBundle from 'shared/app/bundles/wallet';

import GlobalNav from 'shared/app/components/dotcom-global-nav';

const apps = [
  accountPaymentMethodApp,
  accrualEarnRatesBundle,
  geolocationBundle,
  menuBundle,
  orderingApp,
  pickupApp,
  scriptsBundle,
  storeLocatorApp,
  svcCardsBundle,
  userBundle,
  viewportBundle,
  walletBundle,
];

export default composeApp({
  nav: {
    globalNav: {
      component: GlobalNav,
    },
  },
  apps,
});
